import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Footer = () => {
  let date = new Date();
  let year = date.getFullYear();

  const logo = require("../assets/logo.png");

  return (
    <section className="container mx-auto max-w-[1250px]">
      <div className="md:flex py-20 border-notwhite border-b-2 mb-6">
        <span className="md:w-6/12 w-full">
          <Link
            to="/"
            className="mr-[60px] text-[30px] font-bold text-tertiary flex gap-3 items-center heading"
          >
            <img src={logo} alt="BillSpot Logo" className="h-[40px]" />
            BillSpot
          </Link>
          <div className="manrope400 text-oslo manrope500">
            Pay essential bills in few clicks
          </div>
          <div className="flex gap-3 items-center text-oslo text-txs mt-6">
            <Link to="/" target="_blank">
              <FaFacebook />
            </Link>
            <Link to="/" target="_blank">
              <FaXTwitter />
            </Link>
            <Link to="/" target="_blank">
              <FaLinkedin />
            </Link>
            <Link to="/" target="_blank">
              <FaInstagram />
            </Link>
          </div>
        </span>
        <div className="md:w-3/12 w-full md:mt-0 mt-[40px]">
          {/* <h3 className="font-bold heading text-cove mb-3">Contact</h3> */}
          <div className="font-bold heading text-secondary mb-2 text-tlg">
            Head Office
          </div>
          <div className="manrope500 text-oslo mb-2">
            5/7, Dhikirullohi Olasheu Crescent <br />
            Agbowa, Ibeshe,
            <br />
            Lagos State.
          </div>
          <div className="font-bold heading text-secondary mb-2 mt-8 text-tlg">
            Hotline
          </div>
          <span className="manrope500 text-oslo mb-2">
            <Link to="tel:08000000000">+234-8145-0000-0000</Link>
          </span>
          <div className="font-bold heading text-secondary mb-2 mt-8 text-tlg">
            Support & Enquiries
          </div>
          <Link
            to="mailto:info@billspot.app"
            target="_blank"
            className="manrope500 text-oslo mb-2"
          >
            info@billspot.app
          </Link>
        </div>
        <div className="md:w-3/12 w-full md:mt-0 mt-[40px]">
          <h3 className="font-bold heading text-secondary mb-3 text-tlg">
            Solutions
          </h3>
          <div className="manrope500 text-oslo mb-3">Airtime Top Up</div>
          <div className="manrope500 text-oslo mb-3">Cable Tv Subscription</div>
          <div className="manrope500 text-oslo mb-3">Data Bundle Purchases</div>
          <div className="manrope500 text-oslo mb-3">
            Educational Bills & Scratchcards
          </div>
          <div className="manrope500 text-oslo mb-3">SEVIS Fee Payments</div>
          <div className="manrope500 text-oslo mb-3">WES Fee Payments</div>
          <div className="manrope500 text-oslo mb-3">Electricity Bills</div>
          <div className="manrope500 text-oslo mb-3">Virtual Wallets</div>
        </div>
      </div>
      <section className="block md:flex text-oslo manrope500 mb-6">
        <div className="md:w-9/12 w-full text-oslo">
          &copy; {JSON.stringify(year)}, BillSpot
        </div>
        <div className="flex gap-8 items-center md:mt-0 mt-5">
          <Link to="/terms">Terms and Conditions</Link>
          <Link to="/privacy">Privacy Policy</Link>
        </div>
      </section>
    </section>
  );
};

export default Footer;
