import { Helmet } from "react-helmet-async";
import { Fade } from "react-awesome-reveal";
import { AiFillApple } from "react-icons/ai";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { Typewriter } from "react-simple-typewriter";
import Faq from "../components/Faq";
import ScrollToTop from "../components/ScrollToTop";

const Home = () => {
  const img2 = require("../assets/modelL.png");

  return (
    <>
      <Helmet>
        <title>
          BillSpot - Bills, International Airtime, SEVIS and WES Fee Payment
        </title>
        <meta
          name="description"
          content="The one stop point for all essential bill payments, airtime recharge, digital wallets, and more"
        />
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href="https://www.billspot.app" />
      </Helmet>
      <ScrollToTop>
        <section className="bg-tertiary py-[50px]">
          <div className="container max-w-[1250px] mx-auto">
            <div className="md:flex md:flex-row md:gap-4 md:py-[30px]">
              <div className="md:w-6/12 w-full md:order-first order-last md:px-[30px] px-0">
                <h1 className="md:text-dxl text-dsm heading font-extrabold text-white">
                  Cater for all your
                  <br /> essential bills <br />
                  in one app.
                </h1>
                <div className="mt-5 text-water text-tlg">
                  The one stop point for all essential bill payments,
                  <br className="hidden md:block" />
                  airtime recharge, digital wallets,{" "}
                  <br className="hidden md:block" />
                  and more...
                </div>
                <div className="md:mt-[50px] mt-[15px] text-water text-lg italic">
                  Join other BillSpotters on
                </div>
                <div className="flex gap-4 mt-[20px] order-first md:order-last">
                  <span className="md:mt-0 mt-4 manrope500 shadow-md flex items-center gap-3 bg-green text-white rounded-full py-4 px-4 cursor-pointer hover:text-green hover:bg-white ease-in-out duration-300 hover:translate-y-[4px]">
                    <a
                      href="https://apple.com"
                      rel="noreferrer"
                      target="_blank"
                      className="flex gap-3 items-center"
                    >
                      <AiFillApple className="text-[25px]" />{" "}
                      <span className="font-medium md:block hidden">iOS</span>
                    </a>
                  </span>
                  <span className="md:mt-0 mt-4 manrope500 shadow-md flex items-center gap-3 bg-green text-white rounded-full py-4 px-4 cursor-pointer hover:text-green hover:bg-white ease-in-out duration-300 hover:translate-y-[4px]">
                    <a
                      href="https://play.google.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="flex gap-3 items-center"
                    >
                      <IoLogoGooglePlaystore className="text-[25px]" />
                      <span className="font-medium md:block hidden">
                        Android
                      </span>
                    </a>
                  </span>
                </div>
              </div>
              <span className="md:w-6/12 w-full">
                <img src={img2} alt="" className="md:block hidden" />
              </span>
            </div>
          </div>
        </section>
        <section className="container max-w-[1250px] mx-auto py-[100px]">
          <h3 className="heading text-center text-dsm font-extrabold text-off">
            BillSpot Offers
          </h3>
          <div className="mt-10 grid md:grid-cols-3 grid-cols-1 gap-[1px] bg-water">
            <div className="p-3 bg-[#fff]">
              <div className="flex gap-4">
                <span className="w-[30px] mt-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
                    />
                  </svg>
                </span>
                <div className="flex-1">
                  <h3 className="heading text-txl font-semibold text-tertiary">
                    Airtime Recharge
                  </h3>
                  <p className="text-[#616161] text-tsm">
                    Enjoy fast transactions and stay connected without any
                    interruptions. Top up your phone anytime, anywhere. Recharge
                    now!
                  </p>
                </div>
              </div>
            </div>
            <div className="p-3 bg-[#fff]">
              <div className="flex gap-4">
                <span className="w-[30px] mt-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
                    />
                  </svg>
                </span>
                <div className="flex-1">
                  <h3 className="heading text-txl font-semibold text-tertiary">
                    Data Bundles
                  </h3>
                  <p className="text-[#616161] text-tsm">
                    Purchase data bundles online to stay connected anytime,
                    anywhere. Our secure platform offers a variety of plans to
                    suit your needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="p-3 bg-[#fff]">
              <div className="flex gap-4">
                <span className="w-[30px] mt-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 20.25h12m-7.5-3v3m3-3v3m-10.125-3h17.25c.621 0 1.125-.504 1.125-1.125V4.875c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125Z"
                    />
                  </svg>
                </span>
                <div className="flex-1">
                  <h3 className="heading text-txl font-semibold text-tertiary">
                    Cable TV Subscription
                  </h3>
                  <p className="text-[#616161] text-tsm">
                    Easily renew your cable TV subscription online. Enjoy
                    uninterrupted access to your favorite channels with our
                    secure and convenient payment options.
                  </p>
                </div>
              </div>
            </div>
            <div className="p-3 bg-[#fff]">
              <div className="flex gap-4">
                <span className="w-[30px] mt-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z"
                    />
                  </svg>
                </span>
                <div className="flex-1">
                  <h3 className="heading text-txl font-semibold text-tertiary">
                    Electricity bills
                  </h3>
                  <p className="text-[#616161] text-tsm">
                    Pay electricity bills online quickly and securely. Our
                    easy-to-use platform ensures timely payments, helping you
                    avoid interruptions.
                  </p>
                </div>
              </div>
            </div>
            <div className="p-3 bg-[#fff]">
              <div className="flex gap-4">
                <span className="w-[30px] mt-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z"
                    />
                  </svg>
                </span>
                <div className="flex-1">
                  <h3 className="heading text-txl font-semibold text-tertiary">
                    Virtual Wallets
                  </h3>
                  <p className="text-[#616161] text-tsm">
                    Manage your finances effortlessly with our secure and
                    user-friendly digital wallets. Store, send, and receive
                    money with ease.
                  </p>
                </div>
              </div>
            </div>
            <div className="p-3 bg-[#fff]">
              <div className="flex gap-4">
                <span className="w-[30px] mt-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"
                    />
                  </svg>
                </span>
                <div className="flex-1">
                  <h3 className="heading text-txl font-semibold text-tertiary">
                    SEVIS Fee Payment
                  </h3>
                  <p className="text-[#616161] text-tsm">
                    Pay SEVIS fee online to secure your student visa. We ensure
                    your application process is seamless and stress-free.
                  </p>
                </div>
              </div>
            </div>
            <div className="p-3 bg-[#fff]">
              <div className="flex gap-4">
                <span className="w-[30px] mt-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z"
                    />
                  </svg>
                </span>
                <div className="flex-1">
                  <h3 className="heading text-txl font-semibold text-tertiary">
                    WES Fee Payment
                  </h3>
                  <p className="text-[#616161] text-tsm">
                    Our system ensures a smooth transaction, helping you get one
                    step closer to achieving your educational and career goals.
                  </p>
                </div>
              </div>
            </div>
            <div className="p-3 bg-[#fff]">
              <div className="flex gap-4">
                <span className="w-[30px] mt-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5"
                    />
                  </svg>
                </span>
                <div className="flex-1">
                  <h3 className="heading text-txl font-semibold text-tertiary">
                    Educational Bills
                  </h3>
                  <p className="text-[#616161] text-tsm">
                    Easily purchase JAMB EPINS online, enabling you to access
                    your educational exam services instantly.
                  </p>
                </div>
              </div>
            </div>
            <div className="p-3 bg-[#fff]">
              <div className="flex gap-4">
                <span className="w-[30px] mt-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12.75 3.03v.568c0 .334.148.65.405.864l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 0 1-1.161.886l-.143.048a1.107 1.107 0 0 0-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 0 1-1.652.928l-.679-.906a1.125 1.125 0 0 0-1.906.172L4.5 15.75l-.612.153M12.75 3.031a9 9 0 0 0-8.862 12.872M12.75 3.031a9 9 0 0 1 6.69 14.036m0 0-.177-.529A2.25 2.25 0 0 0 17.128 15H16.5l-.324-.324a1.453 1.453 0 0 0-2.328.377l-.036.073a1.586 1.586 0 0 1-.982.816l-.99.282c-.55.157-.894.702-.8 1.267l.073.438c.08.474.49.821.97.821.846 0 1.598.542 1.865 1.345l.215.643m5.276-3.67a9.012 9.012 0 0 1-5.276 3.67m0 0a9 9 0 0 1-10.275-4.835M15.75 9c0 .896-.393 1.7-1.016 2.25"
                    />
                  </svg>
                </span>
                <div className="flex-1">
                  <h3 className="heading text-txl font-semibold text-tertiary">
                    International Airtime
                  </h3>
                  <p className="text-[#616161] text-tsm">
                    Easily purchase international Airtime from our platform,
                    enabling you to access your make calls internationally.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-secondary mb-[100px]">
          <Fade>
            <div className="container py-20 text-white">
              <div className="grid gap-2 justify-items-center items-center md:text-dlg text-txs text-center mx-auto heading">
                BillSpot has made it easy to
                <div className="md:text-dxl text-txs text-yellow">
                  <Typewriter
                    words={[" Buy", " Earn", " Recharge", "Pay Bills"]}
                    loop={false}
                    cursor
                    cursorStyle="|"
                    typeSpeed={70}
                    deleteSpeed={50}
                    delaySpeed={1000}
                  />
                </div>
              </div>
            </div>
          </Fade>
        </section>
        <Faq />
      </ScrollToTop>
    </>
  );
};

export default Home;
