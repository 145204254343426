import { useState } from "react";
import { Link } from "react-router-dom";

const NavBar = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const logo = require("../assets/logo.png");

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  return (
    <div>
      <div className="bg-white py-[10px] px-3">
        <nav className="flex justify-between items-center max-w-[1250px] mx-auto">
          <span className="flex">
            <Link
              to="/"
              className="mr-[60px] text-[20px] font-bold flex gap-3 items-center heading"
            >
              <img src={logo} alt="BillSpot Logo" className="h-[40px]" />
              BillSpot
            </Link>
          </span>

          {isSidebarOpen && (
            <div
              className="fixed inset-0 z-40 bg-black bg-opacity-50"
              onClick={toggleSidebar}
            />
          )}
          <div
            className={`fixed top-0 left-0 h-full w-[90%] bg-white z-50 transform transition-transform ${
              isSidebarOpen ? "translate-x-0" : "-translate-x-full"
            }`}
          >
            <button
              className="absolute top-4 right-4 text-black"
              onClick={toggleSidebar}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
            <ul className="mt-[100px] text-black">
              <Link
                to="/app/register"
                className="mx-4 p-4 mt-[50px] text-white bg-tertiary rounded-md text-center w-full font-bold"
              >
                Pay Bills with BillSpot
              </Link>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default NavBar;
